@import '../../../styles/_variables.sass';;

#audit-heatmap-compare{

    .category-selector{
        text-align: center;
        padding: 5px;
        .item{
            background-color: #f5f5f5;
            padding:20px;
            cursor: pointer;
            &.selected{
                &.orange{
                    background-color: $orange;
                    color: white;
                }
                &.blue{
                    background-color: $blue;
                    color: white;
                }
                
            }
        }
    }
}