@import '../styles/_variables.sass';

:root{
    --top-panel-height: 8px;
}


#top-pane{
    div { height: var(--top-panel-height); width: 20%; float: left; background: $yellow; }
    div:nth-child(2), #top-pane div:nth-child(4) { background: $orange; }
    div:nth-child(3) { background: $blue; }
}

#menu-footer{
    position: fixed;
    bottom: 10px;
    left: 10px;
}

#menu-panel{
    background-color: #e5e5e5;
    height: calc(100vh - var(--top-panel-height));
    min-width: 220px;
}



#content-panel{
    position: relative;
    overflow: auto;
    background-color: rgba(249, 249, 249, 0.719);
    height: calc(100vh - var(--top-panel-height));
    /*padding-bottom: 50px;*/
}