@import '../../styles/_variables.sass';

#fno-map-container{
    height: calc(100%) !important;
    position: relative;
    #MapContainer{
        height: calc(100% ) !important;
        border-radius: 10px;
    }

    .loading-map-panel{
        position: absolute;
        bottom: 0;
        top: 0;
        right: 0;
        left: 0;
        padding-top: 100px;
        background-color: #fff;
    }
    
}