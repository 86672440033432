@import '/src/styles/_variables.sass';


.menu-item{
    width: 100%;
    text-transform: uppercase;
    a{
        text-decoration: none;
        display: block;
        font-size: 16px;
        padding: 10px;
        color: $black;
        &:hover{
        
            background-color: $orange;
            color:white;
            &.level-2{
                background-color: $orange2;
            }
        }
        &.selected{
            background-color: $orange;
            color:white;

            &.level-2{
                background-color: $orange2;
            }
        }

        &.back-btn{
            color:white;
            background-color: $blue;
        }
    }
}
