@import '../../styles/_variables.sass';

.select-large-component{
    .select_large__control{
        height: 70px !important;
        background-color: $gray;
        border-color: transparent;
        margin-top: 5px;
        cursor: pointer;
        flex-wrap: inherit;
    }

      .select_large__menu-list {
        padding: 0px;
      }
      .select_large__menu-list > div{
        line-height: 20px;
        padding: 10px;

        &:hover {
            cursor: pointer;
            outline: none;
            user-select: none;
            background-color: #ebf5ff;
          }
      }
      
      .select_large__dropdown-indicator {
            background: url('../../../public/images/icons/arrow-down.png') no-repeat center transparent; /*$gray!important*/
            margin-right: 5px;
            width: 40px;
        }
        .select_large__dropdown-indicator svg {
            display: none;
        }
        .select_large__indicator-separator {
            display: none;
        }
        .select_large__arrow {
            display: none;
        }

        .select_large__value-container{
            display: flex!important;
            height: 100%;
        }

        &.select_large--is-disabled{
            // hide dropdown list arrow if component disabled
            .select_large__dropdown-indicator{
                display: none;
            }
        }
}