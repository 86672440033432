

$orange: #ff6d3a
$red: #dc3545
$orange2: #ffaa8c
$blue: #21d8de
$green: #198754
$yellow: #ffd55c
$black: #333333
$gray: #f2f2f2
$gray2: #c5c5c5 
$grayText: #cccccc



// Grayscale
$white: #ffffff
$silver: #dddddd
//$gray: #aaaaaa


$main-background-color: $white
$main-text-color: $black
$divider-color: rgba($black, .14)

$main-text-size: 14px
$main-border-radius: 6px

$small-space: 16px
$medium-space: 32px
$large-space: 64px

$phone-size: 600px
$tablet-size: 992px
$desktop-size: 1200px