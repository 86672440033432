@import '../styles/_variables.sass';

.helper-tooltip-component{
    opacity: 1!important;
    //z-index: 9999;
    &:hover{
        opacity: 1!important;
    }
    &:focus{
        opacity: 1!important;
    }
    .react-tooltip{
        opacity: 1!important;
        &:hover{
            opacity: 1!important;
        }
        &:focus{
            opacity: 1!important;
        }
    }

    .question{
        &.blue{
            color: $blue;
        }
    }
}