
#demo1-body{
    #menu{
        height: 50px;
    }
    #content{
        //height: calc(100% - 50px) !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        right: 0;
    }
    #map-overlay{
        height: 100%;
       
    }
    .sub-menu{
        h2{
            
        }
        .order-row{
            padding: 10px;
            border-bottom: 1px solid #cfcfcf;
            &:hover{
                cursor: pointer;
                background-color: #e7e7e7;
            }
            &:first{
                background: red;
            }
        }
    }
    #MapContainer{
        height: 100% !important;

    }
}
