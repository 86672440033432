@import '/src/styles/_variables.sass';

.StatisticDatesFormContainer{
    height: 70px;
    margin:1rem;
    background-color: $gray;
    border: none;
    border-radius: 3px;
    border: 1px solid $gray;
    

    &:hover{
        border: 1px solid hsl(0, 0%, 70%);
        cursor: pointer;
    }

    .icon{
        margin-left: 8px;
        margin-right: 16px;
    }

    .StatisticDatesForm{
        width: 100%;
        .react-datepicker-wrapper{
            width: 100%;
            .datePickerInput{
                width: 100%;
                background-color: $gray;
                border: none;
                &:focus{
                    border: 0;
                }
            }
        }

        .react-datepicker__day--in-range{
            background-color: $orange;
            color: white;
        }

        .react-datepicker__day--in-selecting-range{
            background-color: $orange2;
            color: white;
        }
    }
}