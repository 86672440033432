
.floor-selection-component{
    background-color: #fff;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    user-select: none;
    .floor-btn{
        background-color: rgb(158, 158, 158);
        border-radius: 20px;
        height: 40px;
        width: 40px;
        margin: auto;
        color: #fff;
        padding-top: 4px;
        font-size: 20px;
        margin-bottom: 10px;

        &.isVisible{
            background-color: #ff6d3a;
        }

        &:hover{
            cursor: pointer;
        }
    }
}
