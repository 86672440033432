#timcode-batching-page{
    height: 100%;

    .row#content-table{
        height: calc(100% - 75px);
        .col{
            height: calc(100%);
        }
    }


    .table-component{
        max-height: calc(100% - 22px);
        overflow: auto;
    }
}