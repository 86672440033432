#demo-picking-statistics{
    #order-panel{
        .picking-config-field{
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;

          }
          
          .picking-config-field input{
            background-color: white;
            border: 1px #7e7e7e solid;
            border-radius: 5px;
            height: 23px;
            width: 70px;
            margin-right: 10px;
          }
          
          
          .picking-config-field select{
            background-color: white;
            border: 1px #7e7e7e solid;
            border-radius: 5px;
            
          }
          
          .picking-config-field img{
            height: 30px;
            background-color: #ff6d3a;
            padding: 1px;
            border-radius: 5px;
            margin-right: 5px;
            margin-left: 10px;
          }
    }
    
}