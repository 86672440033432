#admin-client-middleware-request{
    #search-panel{

    }

   
}

.offcanvas-request-details{
    width: 70% !important;
}