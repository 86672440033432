@import '../styles/_variables.sass';

.statistic-card{

    max-width: 250px;
    border-radius: 7px;
    padding: 10px;
    color:white;

    display: flex;

    margin: auto;

    &.blue{
        background-color: $blue;
    }
    &.yellow{
        background-color: $yellow;
    }
    &.orange{
        background-color: $orange;
    }
    &.red{
        background-color: $red;
    }
    &.green{
        background-color: $green;
    }
    >.icon{
        font-size: 50px;
    }

    .data{
        flex-grow: 2;

        .value{
            font-size: 35px;
            text-align: center;

            &.big{
                font-size: 30px;
            }
        }
    
        .title{
            text-align: center;
        }
    }

    &.sm{

        padding: 5px 10px;

        .icon{
            font-size: 35px;
        }

        .value{
            font-size: 25px;
        }
    }

    &.clickable{

        cursor: pointer;
        &:hover{
            opacity: 0.8;
        }
    }

    
}