@import '../styles/_variables.sass';

.storage-file-panel{
    margin-top: 20px;
    margin-bottom: 20px;

    .storage-file-status{
        margin-bottom: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid $gray;
        &.waiting{ }
        &.pending{ }
        &.success{ color: $green}
        &.error{ color: $orange}
    }
}


