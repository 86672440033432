
.loading-component{
    &.fullscreen{
        text-align: center;
        z-index: 999;
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba($color: #bdbdbd, $alpha: 0.8);

    }
}