
#demo-picking-body{
    $menu-height:80px;
    #menu{
        height: $menu-height;
        text-align: center;
        font-size: 25px;
        

        >.item{
            cursor: pointer;
            box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
            outline: none;
            user-select: none;
            background-color: #f9f9f9;
            padding-top: 18px;
            &:hover{
                background-color: #21d8dc;
                color: #f9f9f9;
                &:nth-child(2n){
                    background-color: #ffd55c;
                }
            }

            &.selected{
                background-color: #21d8dc;
                &:nth-child(2n){
                    background-color: #ffd55c;
                }
                color: #f9f9f9;
            }
        }
    }
    #content{
        height: calc(100% - $menu-height) !important;
        position: absolute;
        top: $menu-height;
        right: 0;
        left: 0;
        bottom: 0;
    }
    #map-overlay{
        height: calc(100% - 6px) !important;
       
    }
    .sub-menu{
        height: calc(100%) !important;
        overflow: auto;
        padding: 10px;
        .order-row{
            padding: 10px;
            border-bottom: 1px solid #cfcfcf;
            &:hover{
                cursor: pointer;
                background-color: #e7e7e7;
            }
        }
    }
    #MapContainer{
        height: calc(100% ) !important;

    }

    #fullscreen-loading{
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
