
.login-panel{
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 0 6px #ddd;
    background: #ffffff;

    .header{
        background: #f9f9f9;
        border-radius: 5px 5px 0 0 ;
        padding: 20px;
        border-bottom: 1px solid #eaeaea;
        font-size: 13px;
    }

    .body{
        padding: 20px;
    }
}

.panel-body{
    border-radius: 0 0 5px 5px;
    background: #ffffff;
    padding: 20px;
}
