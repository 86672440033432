.color-picker-component{
    .preview{
        width: 50px;
        height: 40px;
        padding: 5px;
        border: 1px solid #ced4da;
        border-radius: 5px;

        &:hover{
            cursor: pointer;
        }

        .color{
            width: 100%;
            height: 100%;
            border: 1px solid #212529;
            border-radius: 5px;
        }
    }

    .modal-color{
        position: fixed;
        z-index: 999999;
    }
}