@import '../styles/_variables.sass';

.page-header{
    .breadcrumb{

        a:link, a:visited,a:hover,a:active {
            text-decoration: none;
        }

        &.no-admin{
            color: $blue;
            a{
                text-decoration: none;
                color: $blue;
            }
        }
        

        &.admin{
            color: $orange!important;
            a {
                color: $orange!important;
            }
        }
        .item::before{
            content: "\00a0 / \00a0";
        }

        
    }
}