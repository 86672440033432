@import '../../styles/_variables.sass';

.asset-tooltip{
    position:fixed;
    top: 50px;
    left: 50px;
    background-color: $white;
    border: 2px solid $gray;
    padding: 10px;
    border-radius: 5px;
    margin-left: 30px;
}