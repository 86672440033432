
.zoom-component{
    background-color: #fff;
    border-radius: 5px;
    padding: 5px;
    text-align: center;

    .btn-zoom{
        margin: 10px 0 10px 0;
        user-select: none;
        &:hover{
            cursor: pointer;
        }
    }

    .btn-round{
        background-color: #ff6d3a;
        border-radius: 20px;
        height: 40px;
        width: 40px;
        margin: auto;
        color: #fff;
        padding-top: 2px;
        font-size: 25px;
    }
}
