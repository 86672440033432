@import '../../styles/_variables.sass';

.floor-controls{
    position: absolute;
    top:30%;
    right: 20px;

    .floor{
        //width: 30px;
        //height: 30px;

        padding: 10px 20px;
        border-radius: 8px;
        background-color: white;
        margin: 10px 0;
        text-align: center;
        //padding-top: 2px;
        font-weight: bold;
        cursor: pointer;
        
        &.active{
            background-color: $orange;
            color: white;
            
        }
    }
}