@import '../styles/_variables.sass';

.table-component{
    border: 1px solid $gray;
    box-shadow: 0 0 6px #eee;

    margin-top: 25px;

    header{
        justify-content: space-between;
        border-bottom: 2px solid $gray;
        background-color: rgb(249, 249, 249);
        padding: 20px;
    }

    .rdt_TableHeadRow{
        text-transform: uppercase;
        color: $grayText;
        font-size: 12px;
    }

    .rdt_TableRow{
        //text-transform: uppercase;
        cursor: pointer;
        outline: none;

        &:nth-child(2n){
            //background: #f3f3f3;
        }
        &:nth-child(2n + 1){
            //background: #e9e9e9;
        }
        &:hover{
            background-color: $gray;
        }

        .checkbox{
            color:#e2e2e2;
            font-size: 16px;
            &.selected{
                color:$orange;
            }
        }
    }

    .loading{
        .rdt_TableBody{
            opacity: 0.5;
        }
    }


    .lang-flag{
        height: 20px;
        width: 20px;
    }

    .search-panel{
        display: flex;

        input{
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            -ms-border-radius: 3px;
            border-radius: 3px;
            border: 1px solid #eaeaea;
            color: black;
            background: white;


            padding: 2px 10px;
            &:focus{
                outline: none;
            }
        }

        button{
            padding: 2px 10px;
            height: 30px;
        }
    }

}