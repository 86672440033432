@import '../../../../styles/_variables.sass';

.layout-map-details{
    .content{
        background-color: #f9f9f9!important;
        margin-top: 20px;
    }

    .table-component{
        margin-top: 0;
        padding: 20px;
    }

    .nav-link{
        text-transform: uppercase;
        color: $orange !important;

        &.active{
            background-color: $orange2 !important;
            color: white !important;
        }
    }
}
