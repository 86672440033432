@import './_variables';
@import './modalFno.scss';
@import './dataTable.scss';

@font-face {
    font-family: 'Lato-Regular';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/Lato-Regular.eot?') format('eot'),
      url('./fonts/Lato-Regular.ttf') format('truetype'),
      url('./fonts/Lato-Regular.svg#Lato-Regular') format('svg'),
      url('./fonts/Lato-Regular.woff') format('woff');
  }
  @font-face {
    font-family: 'Lato-Bold';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/Lato-Bold.eot?') format('eot'),
      url('./fonts/Lato-Bold.ttf') format('truetype'),
      url('./fonts/Lato-Bold.svg#Lato-Bold') format('svg'),
      url('./fonts/Lato-Bold.woff') format('woff');
  }

body{
    font-family: 'Lato-Regular'!important;
}

.text-blue{
    color: $blue!important;
}

.bg-blue{
    background-color: $blue!important;
    border-color: #269abc!important;
}

.bg-gray{
    background-color: $gray2!important;
    border-color: #858585!important;
}

.bg-orange{
    background-color: $orange!important;
    border-color: #f04f04!important;
}

.text-orange{
    color: #f04f04!important;
}


// remove border and background for a button
.button-transparent{
    background-color: transparent;
    border-style: none;
}

.row-success{
    background-color: #d1e7dd!important;
}


.section-header{
    border-bottom: 2px solid #f2f2f2;
    background-color: rgb(249, 249, 249);
    padding: 20px;
    margin-bottom: 15px;
}