@import '../../../styles/_variables.sass';

.upload-form{
    .dropzone-panel{
        padding: 30px;
        border: 3px dashed $blue;
        text-align: center;
        height: 150px;
        &.drag-active{
            border: 3px dashed $orange;
        }

        &.size-sm{
            height: 85px;
            padding: 15px;
        }
    }
}