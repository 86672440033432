#demo-picking-order-list-sap{
   
    .order-list-item{
        .initial{
            background-color: #fff;
            color: #ff6d3a;
        }
        .optimized{
            background-color: #fff;
            color: #21d8dc;
        }


        &.show-initial{
            .initial{
                font-weight: bold;
            }
        }

        &.show-optimzed{
            .optimized{
                background-color: #21d8dc;
                color: #fff;
            }
        }
        


    }
}