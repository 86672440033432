.RequestPathCompareRow{

    position: relative;

    .show-initial{
        .initial{
            background-color: #ff6d3a;
            color: #fff;
        }

    }
    .show-optimized{
        .optimized{
            background-color: #21d8de;
            color: #fff;
        }

    }

    .loading-missions{
        position: absolute;
        top:0;
        left:0;
        right: 0;
        bottom: 0;
        //background-color: #b6b6b673;
        //padding-top: 20px;
    }
}