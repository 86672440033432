#admin-client-api-tokens{

}

#modal-places-list{
    padding-left: 12px;
    padding-right: 12px;
    .row{
        padding-top: 6px;
        &:nth-child(2n + 1){
            background-color: rgb(221, 221, 221);
        }
    }

}