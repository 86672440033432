
.btn-fno{
    text-transform: uppercase;
    border: none;
    color: #fff;
    padding: 12px;
    border-radius: 3px;

    &.sm{
        font-size: 12px;
        padding: 0px 10px 0px 10px;
    }
}