#statistic-request-optimization-path{
    height: calc(100% - 65px) !important;
    
    #col-map-container{
        height: calc(100%) !important;
        position: relative;
        #MapContainer{
            height: calc(100% ) !important;
            border-radius: 10px;
        }

        .loading-map-panel{
            position: absolute;
            bottom: 0;
            top: 0;
            right: 0;
            left: 0;
            padding-top: 100px;
            background-color: #fff;
        }
    }

    #col-table-container{
        height: calc(100%) !important;
        position: relative;
        overflow: auto;
        .table-component{
            height: calc(100% ) !important;
        }
    }


    .search-input{
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        border-radius: 3px;
        border: 1px solid #eaeaea;
        color: black;
        background: white;


        padding: 2px 10px;
        &:focus{
            outline: none;
        }
    }
    
}