@import '../../styles/_variables.sass';

#menu-panel-admin{
    background-color: #e5e5e5;
    height: calc(100vh - var(--top-panel-height));
    min-width: 220px;
}

#top-pane-admin{
    div { 
        height: var(--top-panel-height); 
        width: 20%; 
        float: left; 
        background: $orange; 
    }
}

#content-panel-admin{
    position: relative;
    overflow: auto;
    background-color: #f3f3f3b7;
    height: calc(100vh - var(--top-panel-height));
    padding-bottom: 50px;
}