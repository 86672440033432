

.modal-fno{
    .modal-header{
        padding: 15px;
        border-radius: 5px 5px 0 0 !important;
        margin: 0;
        font-family: 'Lato-Bold';
        border-bottom: 1px solid #eaeaea;
        background: #f9f9f9;
        color: #333333;
        text-transform: uppercase;

        .modal-title{
            font-size: 15px;
        }
    }
}