#AuditPathCompareRowRender{
    .show-initial{
        .initial{
            background-color: #ff6d3a;
            color: #fff;
        }

    }
    .show-optimized{
        .optimized{
            background-color: #21d8de;
            color: #fff;
        }

    }
}